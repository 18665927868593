import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withModifiers as _withModifiers, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withKeys as _withKeys } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_back_button = _resolveComponent("ion-back-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_select_option = _resolveComponent("ion-select-option")!
  const _component_m_select = _resolveComponent("m-select")!
  const _component_m_input = _resolveComponent("m-input")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_m_textarea = _resolveComponent("m-textarea")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_header, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_toolbar, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_buttons, { slot: "start" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_back_button, {
                    "default-href": "/tabs/home/settings",
                    text: _ctx.$t('Cancel')
                  }, null, 8, ["text"])
                ]),
                _: 1
              }),
              _createVNode(_component_ion_buttons, { slot: "primary" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_button, {
                    onClick: _withModifiers(_ctx.onSubmitForm, ["prevent"])
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_icon, {
                        slot: "end",
                        icon: _ctx.checkmark
                      }, null, 8, ["icon"]),
                      _createTextVNode(" " + _toDisplayString(_ctx.$t('Save')), 1)
                    ]),
                    _: 1
                  }, 8, ["onClick"])
                ]),
                _: 1
              }),
              _createVNode(_component_ion_title, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.task ? _ctx.$t('Edit Task') : _ctx.$t('New Task')), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_content, { fullscreen: true }, {
        default: _withCtx(() => [
          _createVNode("form", {
            action: "#",
            onSubmit: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.onSubmitForm()), ["prevent"])),
            onKeyup: _cache[2] || (_cache[2] = _withKeys(($event: any) => (_ctx.onSubmitForm()), ["enter"]))
          }, [
            _createVNode(_component_ion_list, null, {
              default: _withCtx(() => [
                _createVNode(_component_m_select, {
                  label: _ctx.$t('Category'),
                  name: "task_group_id"
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.store.state.taskGroups, (grp) => {
                      return (_openBlock(), _createBlock(_component_ion_select_option, {
                        key: grp.id,
                        value: grp.id
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(grp.name), 1)
                        ]),
                        _: 2
                      }, 1032, ["value"]))
                    }), 128))
                  ]),
                  _: 1
                }, 8, ["label"]),
                _createVNode(_component_m_input, {
                  label: _ctx.$t('Task'),
                  name: "title"
                }, null, 8, ["label"]),
                (_ctx.task && _ctx.task.recommendation)
                  ? (_openBlock(), _createBlock(_component_ion_item, { key: 0 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_icon, {
                          slot: "start",
                          icon: _ctx.wineSharp,
                          color: "secondary",
                          style: {"filter":"brightness(85%)"}
                        }, null, 8, ["icon"]),
                        _createVNode(_component_ion_label, { class: "ion-text-wrap" }, {
                          default: _withCtx(() => [
                            _createVNode("h3", null, _toDisplayString(_ctx.$t("Expert Tip:")), 1),
                            _createVNode("p", {
                              style: {"color":"var(--ion-color-secondary)","filter":"brightness(85%)"},
                              innerHTML: _ctx.task.recommendation
                            }, null, 8, ["innerHTML"])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                _createVNode(_component_m_textarea, {
                  label: _ctx.$t('Notes'),
                  name: "notes",
                  "auto-grow": "true"
                }, null, 8, ["label"])
              ]),
              _: 1
            })
          ], 32)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}